// 创建文件en/index.js
const en = {
  system: "AIGC PLATFORM",
  WebName: "AIGC PLATFORM",
  Demo: "Demo",
  theme: "Video 2 Video",
  TipsText:
    "Beijing, the capital of ‌ People's Republic of China, is located in north China. It is a famous cultural city with a history of more than 3000 years.",

  firstIndex: "Home",
  firstText: "All content on this website is generated by artificial intelligence",
  language: "Chinese",
  slideModule: {
    warnText: "Please check the slider first",
    slideSuccess: "Check pass",
    clickText: "Click the button to verify",
    direction: "Slide right"
  },
  randomModule: {
    randomText: "Random code",
    randomWarn: "Please enter 4 random digits",
    randomCorrectWarn: "Please enter the correct random code"
  },
  Error: {
    network: "The network connection is down. Please try again later",
    NoneTigs: "No task",
    NoneDownFile: "No resources can be saved",
    EnterCopyFile: "Please enter the text you want to copy",
    exchangeFile: "Please enter the text you want to convert first",
    inputName: "Please fill in the name first",
    uploadFile: "Please upload or record the file first",
    FailNetwork: "Request failed",
    limitCount: "Files are uploaded not more than 5 times",
    AudioPermission: "Unable to obtain audio device",
    Recording: "Recording generation",
    timeout: "Network timeout, please try again later",
    CloneVoiceError: "Failed to generate, please check language"
  },

  DialogModel: {
    PublicMediaLibrary: "Public Media Library",
    ChooseTitle: "Choose",
    Cancel: "Cancel",
    Sumit: "Confirm",
    Userdefined: "User Defined",
    Preview: "Preview",
    CancelPreview: "Cancel Preview",
    exchangeFileSuccess:
      "If the conversion is successful, you can listen and save",
  },
  FileAction: {
    Cancel: "Delete",
    Download: "Download",
    Loading: "Loading",
    incorrectType: "The uploaded file format is incorrect",
    limitSize: "Upload file size cannot exceed 200MB!",
    limit10MSize: "Upload file size cannot exceed 10MB!",
    AllowFile:
      "Only image is allowed to be uploaded",
    AllowNotImageFile: "Only PDF, DOC, DOCX, TXT, Excel or CSV files are allowed to be uploaded",
    loginTips: "Login in first",
    TipsTitle: "Tips",
    deleteSuccess: "Successfully deleted",
    failDeleted: "Deletion failure",
    cancelDelete: "Undelete",
    delete: "Delete",
    SaveSuccess: "Successfully save",
    ExitPrompt: "The account has been logged in, please log out first!",
    UserLoggedOut: "User logged out",
    RequestFailed: "Request Failed",
    ConfirmationPrompt: "Make sure you want to delete the file",
    CustomScene: "Custom Scene",
    UploadScene: "Upload your scene",
    CopySuccess: "Successfully copy",
    deleteChatTitle: "Delete the session?",
    deleteWarning: "After being deleted, it cannot be restored!",
    Confirm: "Confirm",
    Undelete: "Cancel"
  },
  Setting: {
    Profile: "Profile",
    Password: "Password",
  },
  Price: {
    Subscription: "Subscription",
    Free: "Free",
    FreePrice: "Free",
    BuyBtn: "Buy",
    Pro: "Pro",
    EnterPrice: "Enter Price",
    FreeInfo: {
      title01: "1 custom digital person free edition",
      desc01: "Each card can be modified once",
      title02: "10 computing power",
      desc02: "Approximately 10 seconds of video generation time",
    },
    ProInfo: {
      title01: "3 custom digital people free edition",
      desc01: "Each card can be modified three times",
      title02: "3600 computing power",
      desc02: "Approximately equal to 60 minutes of video generation time",
      title03: "1000+ public number people",
      desc03: "Digital people are constantly updated",
      title04: "150+ voice and voice acting",
      desc04: "The language and voice acting are constantly updated",
    }
  },
  priceNew: {
    titleOne: "Choose the most suitable plan for you",
    titleTwo: "AIGC generation to assist your business or interests",
    monthfee: "Monthly billing",
    yearfee: "Billed annually",
    memberComparison: "Member comparison",
    selectPlanOne: {
      titleFont: "free trial",
      pricedanwei: '¥',
      currencyRMB: {
        RMB: 'RMB',
        USD: 'USD'
      },
      priceText: "month",//¥ 0 / 月
      priceYearText: "year",
      anniuBtn: "Go try it out",

      titleTipOne: "Service provision",
      TipOnedescribe01: "10000 Tokens per month",
      TipOnedescribe02: "Export resolution up to 720P",

      titleTipTwo: "include",
      TipTwoOnedescribe01: "1 cloned voice",
      TipTwoOnedescribe02: "5-minute text to speech conversion",
      TipTwoOnedescribe03: "1 interactive head",
      TipTwoOnedescribe04: "1 video template",
    },
    selectPlanTwo: {
      titleFont: "member",
      priceText: "month",//"¥ 249 / 月
      priceYearText: "year",
      anniuBtn: "start using",

      titleTipOne: "Service provision",
      TipOnedescribe01: "1 million tokens per month",
      TipOnedescribe02: "Up to 5s avatar driver",
      TipOnedescribe03: "Export resolution up to 1080P",

      titleTipTwo: "include",
      TipTwoOnedescribe01: "Includes free benefits",
      TipTwoOnedescribe02: "3 cloned voices",
      TipTwoOnedescribe03: "3-hour text to speech conversion",
      TipTwoOnedescribe04: "3 custom video avatars",
      TipTwoOnedescribe05: "3 avatar driver customization",
      TipTwoOnedescribe06: "Faster video processing",
      TipTwoOnedescribe07: "Remove watermark",
    },
    selectPlanThree: {
      titleFont: "Member (Pro)",
      priceText: "month",//¥ 449 / 月
      priceYearText: "year",
      anniuBtn: "start using",

      titleTipOne: "Service provision",
      TipOnedescribe01: "3 million tokens per month",
      TipOnedescribe02: "Up to 10 seconds avatar driver",
      TipOnedescribe03: "Export resolution up to 1080P",

      titleTipTwo: "include",
      TipTwoOnedescribe01: "Includes membership benefits",
      TipTwoOnedescribe02: "10 cloned voices",
      TipTwoOnedescribe03: "10 hours of text to speech conversion",
      TipTwoOnedescribe04: "10 custom video avatars",
      TipTwoOnedescribe05: "10 avatar driver customization",
      TipTwoOnedescribe06: "30+inventory avatars",
      TipTwoOnedescribe07: "Ultra fast video processing",
      TipTwoOnedescribe08: "Remove watermark",
    },
    selectPlanFour: {
      titleFont: "enterprise",
      priceText: "Exactly discussed",
      priceYearText: "year",
      anniuBtn: "Contact Us",

      titleTipOne: "Service provision",
      TipOnedescribe01: "Exactly discussed",

      titleTipTwo: "include",
      TipTwoOnedescribe01: "Includes all benefits of membership (pro)",
      TipTwoOnedescribe02: "Translation proofreading",
      TipTwoOnedescribe03: "Enterprise Security and Privacy",
      TipTwoOnedescribe04: "priority support",
      TipTwoOnedescribe05: "commerce clause",
    },

    tableOne: {
      titleFont: "AI Text",
      columnOneTitle01: "Monthly Tokens",
      columnOneTitle02: "Number of uploaded files",
      columnOneTitle03: "Image analysis",

      columnTwo01: "10 thousand",
      columnTwo02: "Less than 1000 words",
      columnTwo03: "not have",

      columnThree01: "1 million",
      columnThree02: "1 million",
      columnThree03: "100 times",

      columnFour01: "3 million",
      columnFour02: "3 million",
      columnFour03: "300 times",

      columnFive01: "Exactly discussed",
      columnFive02: "Exactly discussed",
      columnFive03: "Exactly discussed",
    },
    tableTwo: {
      titleFont: "AI voice",
      columnOneTitle01: "Monthly Tokens",
      columnOneTitle02: "Advanced Text to Speech (Tokens/Month)",
      columnOneTitle03: "Clone Speech",
      columnOneTitle04: "speech-to-text",

      columnTwo01: "10 thousand",
      columnTwo02: "not have",
      columnTwo03: "1 piece",
      columnTwo04: "5 minutes",

      columnThree01: "1 million",
      columnThree02: "300 thousand",
      columnThree03: "3 of them",
      columnThree04: "3 hours",

      columnFour01: "3 million",
      columnFour02: "1 million",
      columnFour03: "10 of them",
      columnFour04: "10 hours",

      columnFive01: "Exactly discussed",
      columnFive02: "Exactly discussed",
      columnFive03: "Exactly discussed",
      columnFive04: "Exactly discussed",
    },
    tableThree: {
      titleFont: "AI Character and Video Production",
      columnOneTitle01: "Maximum duration of AI character",
      columnOneTitle02: "AI character video generation",
      columnOneTitle03: "Video processing speed (reference)",
      columnOneTitle04: "Photo avatar",
      columnOneTitle05: "Video Template",
      columnOneTitle06: "Customize video avatar",
      columnOneTitle07: "Export Resolution",
      columnOneTitle08: "Remove watermark",
      columnOneTitle09: "Video Download",

      columnTwo01: "5s",
      columnTwo02: "Once",
      columnTwo03: "standard",
      columnTwo04: "1 piece",
      columnTwo05: "1 piece",
      columnTwo06: "",
      columnTwo07: "720P",

      columnThree01: "5s",
      columnThree02: "5 times",
      columnThree03: "quickly",
      columnThree04: "5 of them",
      columnThree05: "5 of them",
      columnThree06: "3 of them",
      columnThree07: "Up to 1080P",

      columnFour01: "10s",
      columnFour02: "10 times",
      columnFour03: "come on",
      columnFour04: "10 of them",
      columnFour05: "10 of them",
      columnFour06: "10 of them",
      columnFour07: "Up to 1080P",

      columnFive01: "Exactly discussed",
      columnFive02: "Exactly discussed",
      columnFive03: "fastest",
      columnFive04: "10 of them+",
      columnFive05: "10 of them+",
      columnFive06: "10 of them+",
      columnFive07: "Up to 4K",
    },
    tableFour: {
      titleFont: "Avatar customization",
      columnOneTitle01: "Number of customized avatar drivers",
      columnOneTitle02: "Maximum duration for each video",
      columnOneTitle03: "Number of video generation attempts",
      columnOneTitle04: "Tu Sheng Tu",
      columnOneTitle05: "Character Dress Up",
      columnOneTitle06: "Attitude action drive",

      // columnTwo01:"",
      // columnTwo02:"",
      // columnTwo03:"",
      // columnTwo04:"",
      // columnTwo05:"",
      // columnTwo06:"",

      columnThree01: "3 of them",
      columnThree02: "5s",
      columnThree03: "5 times",
      columnThree04: "50 times",
      columnThree05: "30 times",
      columnThree06: "10 times",

      columnFour01: "10 of them",
      columnFour02: "10s",
      columnFour03: "10 times",
      columnFour04: "100 times",
      columnFour05: "50 times",
      columnFour06: "30 times",

      columnFive01: "Exactly discussed",
      columnFive02: "Exactly discussed",
      columnFive03: "Exactly discussed",
      columnFive04: "Exactly discussed",
      columnFive05: "Exactly discussed",
      columnFive06: "Exactly discussed",
    },
    describeTitle: "frequently asked questions",
    dropDown: {
      titleFontOne: "What is the price?",
      questionContentOne: "Refer to the above list",
      titleFontTwo: "What happens when I unsubscribe?",
      questionContentTwo: "Cancelling the subscription cancels the billing cycle renewal. This means that you can still use the subscription feature until the end of the valid billing period. However, you do not need to pay any renewal fees.",
      titleFontThree: "What is the difference between monthly subscription and annual subscription?",
      questionContentThree01: "Monthly subscription will be deducted from your payment method on a monthly basis, while annual subscription will be deducted once a year.",
      questionContentThree02: "The actual monthly fee for an annual subscription is lower than that for a monthly subscription.",
      titleFontFour: "What payment methods do you offer??",
      titleFontFour: "What payment methods do you offer?",
      questionContentFour: "We provide: WeChat, Alipay, Stripe and other payment methods",
    },
    freeTrialTitle: "Please try it for free",
    PayMentModel: {
      title: "payment",
      ScanCode: "Scan code payment",
      payment01: "WeChat Pay",
      payment02: "Alipay payment",
      codeTisi: "The QR code has expired",
      RefreshTxt: "Please refresh",
      PaymentAmount: "Payment amount",
      numPayunit: "RMB",
      PaymentMethod: "Payment method"
    }
  },
  topMenu: {
    menu01: "product",
    menu02: "introduction",
    menu03: "Price",
  },
  login: "login",
  register: "register",
  siderMenu: {
    Video2Video: "Character video production",
    AIWritting: "Live streaming copywriting",
    AIvoice: "voice production",
    AICharacter: "AI Character",
    VideoMake: "video production",
    VideoTranslation: "VideoTranslation",

    Customized: "Character customization",
    AIPortrait: "Character generation",
    AIpeopleEdit: "Portrait Editing",
    HalfbodyFullbody: "Half body/whole body",
    Replacement: "Replacement",
    Background: "Background",

    AIAvatar: "AI Avatar",
    Live: "Live",
    LiveStream: "Live Stream"
  },
  AIWritting: {
    title: "AI Chat or Writing",
    modelSelect: "Model Selection",
    select: "Select",
    inputText: "Input Text",
    send: "Send",
    firstwords: "Hello, can I help you?",
    OpenChat: "Start a new conversation",
    ContinueChat: "Continue the conversation",
    NewConversation: "New Conversation",
    Tips: "Big models can make mistakes. Please check important information.",
  },
  AIVoice: {
    ThemeTitle: "AI Voice",
    TexttoSpeech: "Text-to-Speech",
    VoiceSelection: "Voice Selection",
    NoneTigs: "No, please go to the right side to customize the clone",
    NoneMobileTigs: "No custom voice . Go to the 'Voice Clone'",
    CustomVoice: "Custom Voice",
    SelectVoice: "Select Appropriate Voice",
    EnterContent: "Please Enter Content",
    ClickConversion: "One-click Conversion",
    Audition: "Audition",
    Listening: "Listening",
    Download: "Download",
    CloudStorage: "Cloud Storage",
    CloneVoice: "Clone Voice",
    GiveName: "Please give the file a name first",
    UploadFile: "Upload Video or Voice",
    RapidCloning: "Rapid Cloning",
    SelectTextListen: "Select text first and then listen",
    Samples: "Voice Clonning Samples",
    Male: "Male",
    Female: "Female",
    Original: "Original",
    Cloned: "Cloned",
    AnalysisAndTranslation: "Video Speech Analysis and Translation",
    SpeechToText: "Speech-to-Text",
    HereSpeechToText: "Here's speech to text",
    Copy: "One-click Replication",
    TranslatedText: "Translated Text",
    UploadVoiceBtn: "Upload Voice",
    OneselfVoice: "Cloned Oneself Voice",
    Preview: "Preview",
    RandomBtn: "Random Cases",
    SaveTips: "Whether the generated audio is satisfactory or not?",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    UploadTextTigs: "Upload a voice or video",
    warnText: "Upload clean audio and video in the 30s for the best effect",
    defaultText:
      "Beijing, the capital of ‌ People's Republic of China, is located in north China. It is a famous cultural city with a history of more than 3000 years.",
  },
  AITranslation: {
    ThemeTitle: "Character video translation",
    tagTitle01: "Upload voice or video",
    tagTitle02: " speech-to-text",
  },

  AICharacter: {
    title: "Pic 2 Video",
    AvatarTitle: "AI Avatar",
    AiAudio: "AI Audio",
    ImportVoice: "Import Voice",
    UploadVoice: "Upload Voice",
    UploadVoiceText: "Upload Your Voice",
    FaceText: "Upload a picture with a face",
    PlaceholderText: "Please select an appropriate voice",
    EmptyTips: "No audio is available , Customize the audio first",
    SelectAvatar: "Select Avatar",
    CustomAvatar: "Custom Avatar",
    ClickBtn: "Click to Select",
    ShowTips:
      "Limited to 5 second videos, you can use it to customize longer voice videos in ",
    GenerationClick: "One-Click Generation",
    DeleteFile: "Delete",
    DownLoadFile: "DownLoad",
    modelName: " 'Video2Video' Model",
  },

  AIVideoModel: {
    title: "Video 2 Video",
    AiAudio: "AI Audio",
    ImportVoice: "Import Voice",
    UploadVoice: "Upload Voice",
    SelectVideo: "Select Video",
    CustomizedVideo: "Customized Video",
    chooseBtn: "Click to Select",
    UploadText: "Upload a voice messag",
    UploadVideoText: "Upload a video messag",

    ShowTips:
      "Your video will be displayed here, please click the button below to do so Generate",
    GenerationClick: "One-Click Generation",
    DeleteFile: "Delete",
    DownLoadFile: "DownLoad",
    SaveFileBtn: "Cloud Save",
  },

  AIPortrait: {
    title: "Character generation",
    ThemeTitle: "Character generation",
    InputPlaceholder: "Avatar description, such as: a beautiful middle-aged woman",
    gender: '性別',
    genderman: '男',
    genderwoman: '女',
    regenerateClick: "regenerate",
    GenerationClick: "generate-project",
    imgTsText: "Picture display",
    saveBtn: "save",
    DownLoadFile: "download",
    DeleteFile: "delete",
    resetBtn: "reset",
    proTagLists: {
      proTagName01: "Example 1 of prompt words",
      proTagContent01: "A highly realistic portrait of a Chinese working woman, front view, with disheveled hair and no accessories, presenting a beautiful image. She has no glasses or hat, and is photographed in ultra-high definition. During the day, the front light is bright, the background is simple, and she is wearing a suit. Her disheveled hair is elegant, her skin is fair, her facial features are delicate, and she looks straight at the camera with rich details",
      proTagName02: "Example 2 of prompt words",
      proTagContent02: "A sunny and handsome Chinese man, super realistic portrait, frontal, unadorned. Ultra high definition photo, daytime, front light, simple background. Short and sharp hair, sword eyebrows and starry eyes, with a bright smile. Wearing a white pure cotton short sleeved T-shirt, it fits the body and shows healthy lines, showcasing youthful vitality",
      proTagName03: "Example 3 of prompt words",
      proTagContent03: "Japanese anime style girl, portrait photo, no accessories, no glasses, no hat, character photo, ultra high definition, front light, extremely complex details. During the day, her hair is tied in a delicate bun, her big eyes are bright and clear, her smile is sweet, she is wearing a light pink long dress, with a simple background and a light smile on her face",
    },
    AIPortraitpeopleEditError: {
      promptTxt: "Please describe the role",
    }
  },

  AIpeopleEdit: {
    title: "Portrait Editing",
    ThemeTitle: "Portrait Editing",
    AiAudio: "Character generation",

    imgTsText: "Picture display",
    GenerationClick: "generate-project",

    saveBtn: "save",
    DownLoadFile: "download",
    DeleteFile: "delete",
    resetBtn: "reset",
    roeText: {
      SelectText01: "select character",
      SelectText02: "Upload role",
      selectBtn: "select character",
      UploadText: "Upload an image",
      DeleteAnniu: "delete",
      DeleteFile: "delete file",
    },
    ageText: {
      ageSelect: "Select age",
      ageMale: "male",
      ageFemale: "female",
    },
    hairstyleText: {
      SelectText01: "Choose hairstyle",
      SelectText02: "Upload hairstyle",
      selectBtn: "Choose hairstyle",
      UploadText: "Upload an image",
      DeleteAnniu: "delete",
      DeleteFile: "delete file",
    },
    expressionText: {
      ckfxTitle: "Choose emoticons",
      clickSelectBtn: "Click to select",
      DeleteAnniu: "delete",
    },
    peopleEditError: {
      roleImgUploadFile: "请上传角色文件",
      hairstyleUploadFile: "请上传发型文件",
      ageError: "请选择年龄",
      expressionImg_url: "请选择表情",
    }
  },
  HalfbodyFullbody: {
    title: "Half body/whole body",
    ThemeTitle: "Half body/whole body",
    AiAudio: "Character Editor",

    imgTsText: "Picture display",
    GenerationClick: "generate-project",

    saveBtn: "save",
    DownLoadFile: "download",
    DeleteFile: "delete",
    resetBtn: "reset",
    peopleText: {
      SelectText01: "Select portrait",
      SelectText02: "Upload portrait",
      selectBtn: "Select portrait",
      UploadText: "Upload an image",
      DeleteAnniu: "delete",
      DeleteFile: "delete file",
    },
    cuewordText: {
      inputTitle: "cue word",
      InputPlaceholder: "Avatar description, such as: a beautiful middle-aged woman",
      proTagLists: {
        proTagName01: "Example 1 of prompt words",
        proTagContent01: "A highly realistic portrait of a Chinese working woman, front view, with disheveled hair and no accessories, presenting a beautiful image. She has no glasses or hat, and is photographed in ultra-high definition. During the day, the front light is bright, the background is simple, and she is wearing a suit. Her disheveled hair is elegant, her skin is fair, her facial features are delicate, and she looks straight at the camera with rich details",
        proTagName02: "Example 2 of prompt words",
        proTagContent02: "A sunny and handsome Chinese man, super realistic portrait, frontal, unadorned. Ultra high definition photo, daytime, front light, simple background. Short and sharp hair, sword eyebrows and starry eyes, with a bright smile. Wearing a white pure cotton short sleeved T-shirt, it fits the body and shows healthy lines, showcasing youthful vitality",
        proTagName03: "Example 3 of prompt words",
        proTagContent03: "Japanese anime style girl, portrait photo, no accessories, no glasses, no hat, character photo, ultra high definition, front light, extremely complex details. During the day, her hair is tied in a delicate bun, her big eyes are bright and clear, her smile is sweet, she is wearing a light pink long dress, with a simple background and a light smile on her face",
      }
    },
    peopleEditError: {
      portraitImgUploadFile: "请上传人像文件",
      cueword: "请上描述提示词"
    }
  },
  Replacement: {
    title: "Replacement",
    ThemeTitle: "Replacement",
    AiAudio: "Half body/whole body",

    imgTsText: "Picture display",
    GenerationClick: "generate-project",

    saveBtn: "save",
    DownLoadFile: "download",
    DeleteFile: "delete",
    resetBtn: "reset",
    peopleText: {
      SelectText01: "Choose a character",
      SelectText02: "Upload Person",
      selectBtn: "Choose a character",
      UploadText: "Upload an image",
      DeleteAnniu: "delete",
      DeleteFile: "delete file",
    },
  },
  Background: {
    title: "background",
    ThemeTitle: "background",
    AiAudio: "Replacement",

    imgTsText: "Picture display",
    GenerationClick: "generate-project",

    saveBtn: "save",
    DownLoadFile: "download",
    DeleteFile: "delete",
    resetBtn: "reset",
    peopleText: {
      SelectText01: "Select portrait",
      SelectText02: "Upload portrait",
      selectBtn: "Select portrait",
      UploadText: "Upload an image",
      DeleteAnniu: "delete",
      DeleteFile: "delete file",
    },
    dbackgroundText: {
      SelectText01: "select background",
      SelectText02: "Upload Background",
      selectBtn: "select background",
      UploadText: "Upload an image",
      DeleteAnniu: "delete",
      DeleteFile: "delete file",
    },
  },

  LoginModel: {
    EmailLogin: "Email Login",
    PhoneLogin: "Mobile Login",
    UsernameOrEmail: "Email",
    password: "Password",
    Submit: "Submit",
    Reset: "Reset",
    PhoneNumber: "Phone Number",
    VerificationCode: "Verification Code",
    Sendcode: "Send Code",
    GoRegister: "To Register",
    Forgotpassword: "Forgot Password",
    Goback: "Back to Home",
    passwordTigs: "Please enter password",
    phoneTigs: "Please  enter your phone number",
    codeTigs: "Please enter the verification code",
    userTigs: "Please enter your username",
    sendTigs: "Send Failure",
  },

  RegisterModel: {
    EmailRegister: "Email Registration",
    placeholderText: "Please enter email",
    Username: "Username",
    password: "Password",
    PhoneRegister: "Mobile number registration",
    PhoneNumber: "Phone Number",
    VerificationCode: "Verification Code",
    Sendcode: "Send Code",
    Register: "Register",
    Reset: "Reset",
    GoLogin: "Go Login",
    Goback: "Back to Home",
    CorrectEmailFormat: "Please enter the correct email format",
    passwordTigs: "Please enter password",
    phoneTigs: "Please  enter your phone number",
    codeTigs: "Please enter the verification code",
    userTigs: "Please enter your username",
    sendTigs: "Send Failure",
    successTigs: "Registered Successfully!",
  },

  FindPassModel: {
    themeTitle: "Password",
    Email: "Email",
    SendCode: "Send Code",
    VerificationCode: "Verification Code",
    NewPassword: "New Password",
    ConfirmPassword: "Confirm Password",
    Submit: "Submit",
    Reset: "Reset",
    EnterEmailText: "Please enter email",
    CorrectEmailFormat: "Please enter the correct email format",
    passwordTigs: "Please enter password",
    NewPasswordTigs: "Please enter your confirmation password",
    codeTigs: "Please enter the verification code",
    GoLogin: "Go Login",
    Goback: "Back to Home",
    SuccessfulSetting: "The Settings are successful. Log in again",
  },

  MediaLibrary: {
    themeTitle: "User Media Library",
    AudioData: "Audio Data",
    VideoData: "Video Data",
    PictureData: "Picture Data",
	
	PortraitData: "Role Data",
  },

  full: "full-screen display",
  account: "myAccount",
  invoice: "invoice",
  reconciliation: "Statement",
  record: "recording",
  report: "report",
  setting: "Settings",

  tips: "{name} Username and password are filled in casually {cont}",
  administrator: "administrator",
  placeUser: "please enter user name",
  palcePass: "Please enter your password",
  palceCode: "please enter verification code",
  accounts: "accounts",
  password: "password",
  code: "Verification code",
  footer: {
    CompanyProfile: "Company Profile",
    introduction: "Company Introduction",
    UserAgreement: "User Agreement",
    Privacy: "Privacy Agreement",
    Aboutus: "About Us",
    ContactUs: "Contact Us",
    CustomerService: "Customer Service",
    TechnicalSupport: "Technical Support",
  },

  Table: {
    View: "View",
    Download: "Download",
    Delete: "Delete",
    Preview: "Preview",
    Time: "Time",
    label: "Item",
    Operation: "Operation",
    ImageToVideoTitle: "AI Video Recording",
    VideoHistoryTitle: "Video Generation Recording",
    ImageHistoryTitle: "Picture Management",
    VoiceCloneHistoryTitle: "Cloned Voice Data",
    VoiceName: "Name",
    TTSSpeech: "TTS",
    Playing: "Playing",
    Play: "Play",
    NoMoreData: "No More Data",
  },
  RoleTable:{
	   roletitle: "Character generation data",
	  
	   Name: "名字",
	   imgUrl: "图片",
	   Time: "时间",
	   Operation: "操作",
	   
	   gotoTxt: "go to",
	   pageTxt: "/页",
	   totalTxt: "操作",
	  
	   View: "查看",
	   Download: "下载",
	   Delete: "删除",
	   Preview: "预览",
	   
	   NoMoreData: "暂无数据",
 },
  Company: {
    title: "Company Introduction:",
    desc01:
      "Kamikasi Tech Ltd. is a pioneering AI startup known for its innovation and creativity. As an official member of the NVIDIA Inception Program, we are committed to advancing AI technologies.",
    desc02:
      "Founded in July 2022 by entrepreneur Gu Xing and senior AI expert Chen Ping, along with a team of talented professionals, Kamikasi Tech focuses on the research, development, and commercialization of specialized digital humans and Artificial Intelligence Generated Content (AIGC) for the media production industry.",
    desc03:
      "We offer comprehensive solutions for the creation, application, and management of interactive digital avatars, catering to both enterprises and individuals. By harnessing state-of-the-art AI technology, Kamikasi Tech has made significant strides in developing hyper-realistic, intelligent digital humans capable of autonomously performing tasks driven by AI models. These advancements open up new horizons across sectors such as entertainment, education, and beyond",
    desc04:
      "With a vision to become a global leader in AI digital human development and operation, Kamikasi Tech has trained and deployed hundreds of AI models, continuously pushing the boundaries of innovation. Our mission is to enhance the synergy between AI and creativity, delivering unmatched experiences to users and reshaping the future of media industries.",
  },

  AccountSetting: {
    title: "Account Setting",
    ChangeUserName: "Modifying User Name",
    UserName: "User Name",
    NewName: "New User Name",
    newnameWarning: "The new user name cannot be empty",
    Submit: "Submit",
    Reset: "Reset",
    ChangeEmail: "Modify Email",
    Email: "Email",
    NewEmail: "New Email",
    SendCode: "Send Code"
  }

};
export default en;
